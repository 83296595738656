<template>

  <div class="sborAI__container">
    <v-progress-linear v-if="!loadedUserFiles" indeterminate></v-progress-linear>
    <div v-else class="sborAI__main">

      <div class="sborAI__instruction-container">
        <v-btn class="sborAI__instruction-btn">
          <a
            download="Инструкция.pdf"
            href="https://files.ined.ru/adminpanel/cms_article_editor_32_66666_6c4c9c9d45eea6445d3a64d50baa7cbd.pdf"
            target="_blank"            >
          Открыть инструкцию по отправке документов
        </a>
        </v-btn>
      </div>
      <div v-if="showNoteBlock" class="sborAI__note">
        <div class="sborAI__note-title">Замечания от оператора:</div>
        <SborAIItem key="ADMIN_FILE"
                    :aiItem="adminFile"
                    :editable="isAdmin"
                    :loading-file="loadingFileToServer.fileType"
                    :upload-success="loadingFileToServer.value"
                    :user-files="userFiles"
                    class="form__item"
                    @replace-file="replaceFile($event)"
                    @upload-file="uploadFile($event)"
                    @delete-file="deleteFile($event)"
        />
        <div class="sborAI__note"><p>Уважаемые коллеги!</p>
          <p>В рабочем кабинете размещена актуальная редакция Справки об устранении недостатков в отчетных документах.</p>
          <p style="text-align: center">Просим в срок до 10.03.2022 г. проработать указанные замечания и разместить в рабочем кабинете Справку об устранении недостатков в отчетных документах и актуализированные отчетные документы <strong>с подписью руководителя (уполномоченного лица) и печатью организации</strong>, а также доработанные программы магистратуры по профилю «искусственный интеллект».</p>
          <p>Доработанные образовательные программы (полным комплектом) просим разместить в разделе «Иные документы».</p>
        </div>
      </div>

      <div class="sborAI__form">
        <SborAIItem v-for="item in aiItems"
                    :key="item.fileType"
                    :aiItem="item"
                    :editable="!blockStatus"
                    :loading-file="loadingFileToServer.fileType"
                    :upload-success="loadingFileToServer.value"
                    :user-files="userFiles"
                    class="form__item"
                    @replace-file="replaceFile($event)"
                    @upload-file="uploadFile($event)"
                    @delete-file="deleteFile($event)"
        />
      </div>
      <div class="sborAI__actions">
        <v-btn v-if="!blockStatus"
               :disabled="!checkAllFilesUploaded"
               :loading="filesLoadingForCheck"
               class="sborAI__btn-send"
               color="primary"
               elevation="2"
               x-large
               @click="sendFilesToCheck"
        >{{ checkAllFilesUploaded ? 'Отправить на проверку' : 'Не все файлы загружены' }}
        </v-btn>
        <v-alert
            v-else
            class="sborAI__alert-success"
            outlined
            type="success"
        >Файлы на проверке
        </v-alert>
      </div>
    </div>

  </div>

</template>

<script>
import SborAIItem from "@/components/SborAI/SborAIItem";
import request from "@/services/request";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SborAI",
  components: {
    SborAIItem
  },
  props: {
    year: {
      required: true
    },
  },
  data() {
    return {
      adminFile: {
        title: 'Файл с замечаниями для организации',
        placeholder: 'Загрузить (до 20МБ)',
        fileType: 'ADMIN_FILE_'+this.year
      },
      aiItems: [
        {
          title: 'Отчет о расходах, источником финансового обеспечения которых является грант',
          placeholder: 'Загрузить отчет (до 20МБ)',
          templateFileLink: 'https://files.ined.ru/adminpanel/cms_article_editor_32_66666_f949cf650837b00da7f9e4cdf63955e3.docx',
          fileType: 'OTCHET_RASHODY_'+this.year
        },
        {
          title: 'Отчет о достижении значений результатов предоставления гранта',
          placeholder: 'Загрузить отчет (до 20МБ)',
          templateFileLink: 'https://files.ined.ru/adminpanel/cms_article_editor_32_66666_ca4abcce75bcfdfc9eea8755487d98b9.docx',
          fileType: 'OTCHET_ZNACHENIYA_'+this.year
        },
        {
          title: 'Отчет о достижении контрольных точек',
          placeholder: 'Загрузить отчет (до 20МБ)',
          templateFileLink: 'https://files.ined.ru/adminpanel/cms_article_editor_32_66666_6e0f1ae8ad9c20111183430066b38fae.docx',
          fileType: 'OTCHET_KONTROLNYE_TOCHKY_'+this.year
        },
        {
          title: 'Иные документы',
          placeholder: 'Загрузить файл (до 500МБ)',
          fileType: 'DOCUMENT_'+this.year,
        },
        {
          title: 'Справка об устранении недостатков в отчетных документах',
          placeholder: 'Загрузить справку (до 20МБ)',
          fileType: 'SPRAVKA_'+this.year
        }
      ],
      userFiles: [],
      loadingFileToServer: {
        value: false,
        fileType: '',
      },
      loadedUserFiles: false,

      filesLoadingForCheck: false,
      blockStatus: false,
    }
  },
  mounted() {
    this.getUserFiles();
    this.getBlockStatus()
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),

    async getUserFiles() {
      this.loadedUserFiles = true;
      const res = await request({
        endpoint: `/api/files/org/${this.year}/${this.getOrgID}`,
        method: 'GET',
      });
      this.userFiles = res.data
      this.loadedUserFiles = true;
    },
    async getBlockStatus() {
      try {
        const res = await request({
          endpoint: `/api/sbor-ai/block/${this.year}/${this.getOrgID}`,
          method: 'GET'
        })
        if (res.status === 200 && res.data) {
          this.blockStatus = res.data.block
        }
      } catch (error) {
        console.error('error to block org', error)
      }
    },
    async uploadFile(event) {
      this.loadingFileToServer.value = true;
      this.loadingFileToServer.fileType = event.fileType;
      let fd = new FormData();
      fd.append('file', event.file);
      fd.append('fileType', event.fileType);
      fd.append('orgId', this.getOrgID);
      fd.append('year', this.year);
      try {
        const res = await request({
          endpoint: `/api/files/`,
          method: 'POST',
          body: fd,
          headers: {"Content-Type": 'multipart/form-data'}
        });
        const overlapIndex = this.userFiles.findIndex(file => file.fileType === res.data.fileType);
        if (overlapIndex !== -1) {
          this.userFiles.splice(overlapIndex, 1, {...res.data});
        } else {
          this.userFiles.unshift({...res.data})
        }
        this.loadingFileToServer.value = false;
        this.loadingFileToServer.fileType = '';
      } catch (error) {
        this.loadingFileToServer.value = false;
        this.loadingFileToServer.fileType = '';
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка загрузки',
          timeout: 3000
        })
      }
    },
    async deleteFile(event) {
      try {
        const res = await request({
          endpoint: `/api/files/${event.id}`,
          method: 'DELETE',
        });

        if (res.status === 200) {
          const index = this.userFiles.findIndex(file => file.id === event.id);
          this.userFiles.splice(index, 1)
        }
      } catch (error) {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка удаления',
          timeout: 3000
        });
      }
    },
    async replaceFile(event) {
      await this.deleteFile(event)
      await this.uploadFile(event)
    },
    async sendFilesToCheck() {
      this.filesLoadingForCheck = true
      try {
        const res = await request({
          endpoint: `/api/sbor-ai/block/${this.year}/${this.getOrgID}/true`,
          method: 'POST'
        })
        if (res.status === 201 && res.data) {
          this.blockStatus = res.data.block
        }
        this.filesLoadingForCheck = false
      } catch (error) {
        console.error('Blocking org failed', error)
        this.filesLoadingForCheck = false
      }
    },
  },
  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    isAdmin() {
      return !!this.authData.realm_access.roles.find(el => el === 'ADMIN_ROLE');
    },
    showNoteBlock() {
      if (this.userFiles.find(file => (file.fileType === 'ADMIN_FILE'))) return true;
      return !!this.authData.realm_access.roles.find(el => el === 'ADMIN_ROLE');
    },
    checkAllFilesUploaded() {
      const requiredFiles = ['OTCHET_RASHODY_'+this.year, 'OTCHET_ZNACHENIYA_'+this.year, 'OTCHET_KONTROLNYE_TOCHKY_'+this.year]
      const haveAdminFile = this.userFiles.find(file => (file.fileType === 'ADMIN_FILE'))
      if (haveAdminFile) {
        requiredFiles.push('SPRAVKA_'+this.year)
      }
      const userFiles = this.userFiles.filter(file => requiredFiles.indexOf(file.fileType) !== -1)
      if (haveAdminFile) {
        return userFiles.length >= 4
      } else {
        return userFiles.length >= 3
      }
    },
    getOrgID() {
      if (this.authData.realm_access.roles.find(el => el === 'ADMIN_ROLE')) {
        if (this.$route.query?.orgId) {
          return this.$route.query.orgId;
        }
      }
      return this.authData.orgID
    },
  }
}
</script>

<style>

.sborAI__container {
}

.sborAI__main {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.sborAI__note {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #FFFFFF;
  border: 1px solid #00599B;
  border-radius: 7px;
}

.sborAI__instruction-container {
  margin: 20px auto 30px;
}

.sborAI__instruction-btn {
  background-color: #FFFFFF !important;
}

.sborAI__instruction-btn > span > a {
  color: #00599B;
  font-size: 1.1rem !important;
}

.sborAI__note-title {
  font-size: 1.1rem;
  font-weight: bold;
  color: #00599B;
}

.sborAI__note-link {
  padding: 0 5px;
  color: #ED393E;
  background-color: #fff6fd;
  border-radius: 3px;
  cursor: pointer;
}

.sborAI__note-link:hover {
  text-decoration: underline;
}

.form__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.sborAI__actions {
  align-self: flex-end;
  margin-top: 15px;
  color: #00599B;
  font-size: 1.1rem;
  font-weight: 600;
}

.sborAI__btn-send:disabled > span {
  color: #666666;
  font-weight: bold;
}

.sborAI__alert-success {
  display: inline-block;
  align-self: flex-end;
  font-weight: bold;
  text-transform: uppercase;
}

</style>