<template>
  <div class="form__item">

    <div class="form__item-title">{{ aiItem.title }}
      <div v-if="checkUserFile" class="form__item-userFile form__item-userFile-uploaded">
        Загружено: <a :download="checkUserFile.originalFileName" :href="getUserFileLink"
                      class="form__item-userFile-link">{{ checkUserFile.originalFileName }}</a>
      </div>
      <div v-else class="form__item-userFile form__item-userFile-none">Загруженного файла нет</div>
    </div>

    <div class="form__item-buttons">
      <v-btn
          v-if="aiItem.templateFileLink"
          class="form__item-btn"
          color="primary"
          dark
          elevation="2"
      >
        <a :href="aiItem.templateFileLink" download="">Скачать шаблон</a>
      </v-btn>
      <v-file-input v-model="fileToUpload"
                    :error="error"
                    :error-messages="errorMessage"
                    :loading="showFileIsUploading"
                    :placeholder="aiItem.placeholder"
                    :rules="rules"
                    class="form__item-input-file"
                    clearable
                    prepend-icon=""
                    show-size
                    v-if="editable"
                    @change="hideUploadError($event)"
      ></v-file-input>
      <v-icon
          :disabled="!canUpload"
          class="form__item-action"
          color="#00599B"
          size="30"
          title="Отправить файл"
          v-if="editable"
          @click="uploadFile"
      >mdi-file-send
      </v-icon>
      <v-icon
          :disabled="!checkUserFile && !fileToUpload"
          class="form__item-action"
          color="#00599B"
          size="30"
          title="Удалить файл"
          v-if="editable"
          @click="deleteFile"
      >mdi-delete-empty
      </v-icon>
    </div>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "SborAIItem",
  props: {
    aiItem: {
      type: Object,
      required: true
    },
    userFiles: {
      type: Array,
      default: () => []
    },
    loadingFile: {
      type: String,
      default: ''
    },
    uploadSuccess: {
      type: Boolean,
    },
    editable: {
      default: true,
      type: Boolean,
    }
  },
  data() {
    return {
      fileToUpload: null,
      error: false,
      errorMessage: '',
      rules: [
        value => value != null || 'Выберите файл',
        value => value?.size < this.maxFileSize.num || `Размер файла не должен превышать ${this.maxFileSize.mb}!`
      ],
      maxFileSize: {},
    }
  },
  mounted() {
    this.getMaxFileSize()
  },
  computed: {
    canUpload() {
      return this.fileToUpload?.size < this.maxFileSize.num
    },
    checkUserFile() {
      let userFile = null
      this.userFiles.map(file => {
        if (file.fileType === this.aiItem.fileType) {
          userFile = file
        }
      })
      return userFile
    },
    getUserFileLink() {
      return `/api/files/${this.checkUserFile.id}`
    },
    showFileIsUploading() {
      return this.loadingFile === this.aiItem.fileType
    },
  },
  watch: {
    uploadSuccess: function () {
      this.fileToUpload = null;
    }
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),

    getMaxFileSize() {
      if (this.aiItem.otherDocuments) {
        this.maxFileSize = {
          mb: '500МБ',
          num: '500000000'
        }
      } else {
        this.maxFileSize = {
          mb: '20МБ',
          num: '20000000'
        }
      }
    },
    hideUploadError(event) {
      console.log(this.error)
      if (event && event?.size < this.maxFileSize.num) {
        this.error = false;
        this.errorMessage = '';
      }
    },
    uploadFile() {
      this.error = false;
      this.errorMessage = '';
      if (this.checkUserFile) {
        this.$emit('replace-file', {
          'file': this.fileToUpload,
          'id': this.checkUserFile['id'],
          'fileType': this.aiItem.fileType
        });
      } else {
        this.$emit('upload-file', {'file': this.fileToUpload, 'fileType': this.aiItem.fileType});
      }
    },
    async deleteFile() {
      if (this.checkUserFile) {
        this.$emit('delete-file', {
          'id': this.checkUserFile['id'],
        });
      } else {
        this.fileToUpload = null;
      }
    },
  },
}
</script>

<style scoped>

.form__item-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form__item-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.form__item-userFile {
  font-size: 0.8rem;
}

.form__item-userFile-uploaded > span, .form__item-userFile-none {
  font-weight: bold;
  border-radius: 3px;
}

.form__item-userFile-link {
  color: inherit;
  cursor: pointer;
}

.form__item-userFile-link:hover {
  text-decoration: underline;
}


.form__item-userFile-uploaded {
  color: #29A128;
  background-color: #EBF9EB;
}

.form__item-userFile-none {
  padding: 0 3px;
  color: #00559B;
  background-color: #E3EFFA;
  border-radius: 3px;
}

.form__item-btn {
  padding: 0 !important;
}

.form__item-btn > span > a {
  display: block;
  padding: 15px;
  color: #ffffff;
}

.form__item-input-file {
  min-width: 250px;
  margin: 0 20px;
  font-size: 0.8rem;
}

.form__item-action {
  margin-left: 7px;
  cursor: pointer;
}

</style>