<template>
  <div>
    <div class="links">
      <div class="links__flex">
        <router-link class="link-toMain" to="/">На главную</router-link>
        <div>
        <router-link class="link-toMain mr-2" to="2022">2022 год (текущий)</router-link>
        <router-link class="link-toMain" to="2021">2021 год</router-link>
        </div>
      </div>
      <div class="sborAI__title-main">
        Сбор отчетов по реализации грантов на
        разработку программ бакалавриата и программ магистратуры по профилю «искусственный интеллект», а также на
        повышение квалификации педагогических работников образовательных организаций высшего образования в сфере
        искусственного интеллекта
      </div>
      <div class="links__flex">
        <router-link v-if="hasQuery" class="link-exitOrg" :to="`/sbor_artificial_intelligence/${year}`">Выйти из организации
      </router-link>
        <v-btn class="sborAI__btn-regulations">
          <a
              download="Регламент представления получателями грантов отчетных документов.pdf"
              href="https://files.ined.ru/adminpanel/cms_article_editor_32_66666_00fd0cb7aca6b3a6d7657a6d5ec90d51.pdf"
              target="_blank">
            Регламент
          </a>
        </v-btn>
      </div>
    </div>
    <sbor-a-i-admin :key="year" :year="year" v-if="isAdmin"></sbor-a-i-admin>
    <sbor-a-i :key="year" :year="year" v-else></sbor-a-i>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SborAI from "@/components/SborAI/SborAI";
import SborAIAdmin from "@/components/SborAI/admin/SborAIAdmin";

export default {
  name: "ViewSborAI",
  components: {SborAIAdmin, SborAI},
  props: ['year'],
  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {getAuthUserData: 'getAuthUserData'}),
    ...mapGetters('auth', {getAuthUserDataError: 'getAuthUserDataError'}),
    ...mapGetters('auth', {getAuthUserDataLoading: 'getAuthUserDataLoading'}),
    hasQuery() {
      return this.$route.query?.orgId
    },
    isAdmin() {
      if (this.authData.realm_access.roles.find(el => el === 'ADMIN_ROLE')) {
        if (this.$route.query?.orgId) {
          return false;
        }
        return true;
      }
      return false;
    }
  },
}
</script>

<style scoped>
.sborAI__title-main {
  margin: 10px 0 20px;
  color: #00559b;
  font-size: 1.1rem;
  text-align: center;
}

.links__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sborAI__btn-regulations {
  background-color: #FFFFFF !important;
}

.sborAI__btn-regulations > span > a {
  color: #00599B;
  font-size: 1.1rem !important;
}
</style>