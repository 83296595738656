<template>
  <WrapperPreviewCategory customClass="section-preview_lk">
    <v-row>
      <v-col>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            hide-details
            label="Поиск"
            single-line
        ></v-text-field>
      </v-col>
    </v-row>
    <v-progress-linear v-if="loadingData" indeterminate></v-progress-linear>
    <v-data-table
        :headers="getHeaders"
        :items="getOrgList"
        :search="search"
        class="mt-5"
        item-key="orgId"
        v-else
    >
      <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.orgId">
          <td>{{ item.orgId }}</td>
          <td>
            {{ item.orgName }}
          </td>
          <td>
            {{ item.blocked ? 'Заблокирован' : 'Не заблокирован' }}
          </td>
          <td class="table__buttons">
            <v-btn class="mt-2 blue__v-btn"
                   @click="enterAsOrg(item)"
            >
              Войти в организацию
            </v-btn>
          </td>
          <td>
            <v-btn class="mt-2 blue__v-btn"
                   @click="clickUnblock(item)"
            >
              {{ item.blocked ? 'Разблокировать' : 'Заблокировать' }}
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
  </WrapperPreviewCategory>
</template>

<script>
import {mapActions} from 'vuex';
import request from "@/services/request";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";

export default {
  name: "SborAIAdmin",
  components: {
    WrapperPreviewCategory
  },
  props: {
    year: {
      required: true
    },
  },
  data() {
    return {
      uploadedFileLoading: false,
      exportFileLoading: false,
      loadingBlockEnterData: false,
      search: '',
      organizations: [],
      loadingData: false,
    }
  },
  computed: {
    getHeaders() {
      return [
        {text: 'ID', filterable: true, value: 'orgId'},
        {text: 'Наименование ораганизации', filterable: true, value: 'orgName'},
        {text: 'Статус', width: 150, sortable: true, filterable: true, value: 'blocked'},
        {text: 'Действия', sortable: false, filterable: false},
        {text: '', sortable: false, filterable: false},
      ]
    },
    getOrgList() {
      if (this.organizations !== null) {
        return this.organizations.filter((el) => (el?.orgId !== null))
            .sort((a, b) => ((a.id - b.id) > 0 ? 1 : -1))
      } else
        return [];
    }
  },
  created() {
    this.loadOrgList();
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),

    filterOrg(value, orgFilter, item) {
      // console.log(value,orgFilter,item)
      return orgFilter != null && item.orgName.toLowerCase().indexOf(orgFilter.toLowerCase()) !== -1
    },
    async loadOrgList() {
      this.loadingData = true;
      const res = await request({
        endpoint: `/api/sbor-ai/orglist/${this.year}`,
        method: 'get',
      });
      if (res.status === 200) {
        this.loadingData = false
        this.organizations = res.data;
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка загрузки заполненных контактов',
          timeout: 5000
        });
        this.loadingData = false
      }
    },
    enterAsOrg(item) {
      this.$router.push({path: "", param: {year: this.year}, query: {orgId: item.orgId}});
    },
    async clickUnblock(item) {
      this.loadingBlockEnterData = true;
      const agree = confirm(`${item.blocked ? 'Разблокировать' : 'Блокировать'} ввод?`);
      if (agree) {
        const res = await request({
          endpoint: `/api/sbor-ai/block/${this.year}/${item.orgId}/${item.blocked ? 'false' : 'true'}`,
          method: 'post'
        });
        if (res.status === 201) {
          await this.addNotification({
            notificationStatus: 'success',
            notificationMessage: `Организация ${item.blocked ? 'разблокирована' : 'заблокирована'}`,
            timeout: 5000
          });
          item.blocked = res.data.block;
        } else {
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка блокировки данных',
            timeout: 5000
          })
        }
      }
      this.loadingBlockEnterData = false;
    }
  },
}
</script>

<style scoped>

</style>